// transform and map data to customer profile component
// used in the update profile component because the request data schema is different
// for updating ach fields.
const CustomerACHFields = function(data) {
  let checkingAccount = '';
  let routingNumber = '';
  let AccountType = '';

  return { 
    checkingAccount: checkingAccount,
    routingNumber: routingNumber,
    AccountType: AccountType,
  }
}


export default CustomerACHFields;