// ACHFields
//
//  desc:
//    group of fields related and required for ACH processing
//
//  props:
//    formdata:                       (required)
//      checkingAccount:              data for input field
//      routingNumber:                data for input field
//      checkingAccount:              data for input field
//      routingNumber:                data for input field
//    onValidate:           (optional) a callback function for when the user has entered input
//    onDataChange:         (optional) a callback function for when the user has entered input
//

import React, { Component } from 'react';
import AchInput, { 
  validateCheckingAccount,
  validateRoutingNumber 
} from '../../AchInput';
import {Validation, Change} from '../../Utility';
import PropTypes from 'prop-types';
import DropDown from '../../DropDown';


class ACHFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      isCheckingAccountValidVisited: false,
      isCheckingRoutingNumberVisited: false,
    }
    
    this.onBillingCheckingAccountChange = this.onBillingCheckingAccountChange.bind(this);
    this.onBillingRoutingNumberChange = this.onBillingRoutingNumberChange.bind(this);
    this.onAccountTypeChange = this.onAccountTypeChange.bind(this)
    this.onSECCodeChange = this.onSECCodeChange.bind(this);

    this.validateCheckingAccount = this.validateCheckingAccount.bind(this);
    this.validateRoutingNumber = this.validateRoutingNumber.bind(this);
    this.accountTyeOptions = { choose:"choose an option", Checking:"Checking", Savings:"Savings" }
  }

  onBillingCheckingAccountChange(value) {
    if (this.props.onBillingCheckingAccountChange) {
      this.props.onBillingCheckingAccountChange(value);
    }
  }

  onBillingRoutingNumberChange(value) {
    if (this.props.onBillingRoutingNumberChange) {
      this.props.onBillingRoutingNumberChange(value);
    }
  }
  onSECCodeChange(value){
    if (this.props.onSECCodeChange) {
      this.props.onSECCodeChange(value);
    }
  }

  onAccountTypeChange(keyValue) {
    let value = keyValue.split(" ")[0];
    if (this.props.onAccountTypeChange) {
      this.props.onAccountTypeChange(value)
    }
  }

  validateRoutingNumber(validity) {
    this.setState({
      isCheckingRoutingNumberVisited: true
    });
  }
  
  validateCheckingAccount(validity) {
    this.setState({
      isCheckingAccountValidVisited: true,
    });
  }

  render() {
    const checkingAccount = this.props.formdata.checkingAccount;
    const routingNumber = this.props.formdata.routingNumber;
    const accountType = this.props.formdata.AccountType;
    const autoFocus = this.props.autoFocus;
    return (
      <div>  
        <AchInput
          name='checkingAccount'
          validations={ {
            // note: could use build in validator and would clean up code.
            isCheckingAccountValid: function(values, value) {
               return validateCheckingAccount(value);
            }
          } }
          validationErrors={ {
            'isCheckingAccountValid': 'Invalid checking account number',
            'isDefaultRequiredValue': 'This field is required',
          } }
          required
              autoFocus={ autoFocus }
              value={ checkingAccount }
              maxLength="20"
              id="checkingAccount"
              tooltipText="Account Number"
              label="Account Number"
              onChange={ this.onBillingCheckingAccountChange }
              //onValidate={ this.validateCheckingAccount }
              //isValid={ this.props.isCheckingAccountValid || !this.state.isCheckingAccountValidVisited }
        />
        
        <AchInput
          name='routingNumber'
          validations={ {
            // note: could use built in validator and would clean up code more.
            isRoutingNumberValid: function(values, value) {
              return validateRoutingNumber(value);
            }
          } }
          validationErrors={ {
            'isRoutingNumberValid': 'Invalid routing number',
            'isDefaultRequiredValue': 'This field is required',
          } }
          required
              id="routingNumber"
              value={ routingNumber }
              maxLength="9"
              tooltipText="Routing number of the Account Number"
              label="Routing Number"
              onChange={ this.onBillingRoutingNumberChange }
              //onValidate={ this.validateRoutingNumber }
              //isValid={ this.props.isCheckingRoutingNumber || !this.state.isCheckingRoutingNumberVisited}
        />
      {this.props.formdata.hasTraceACH &&
        <>
          <DropDown
            id='AccountType'
            validations={{
              validateRequired: function(values, value) {
                if (value.endsWith('choose')) {
                  return false;
                } else {
                  return true; //not required.
                }
              }
            }}
            validationErrors={{
              'validateRequired': 'This is required',
            }}
            name={"AccountType"}
            label={"Account Type"}
            options={this.accountTyeOptions}
            onChange={this.onAccountTypeChange}
            value={accountType}
            required={true}
            inError={false} 
          />
        </>
      }
      </div>
    );
  }
}

ACHFields.propTypes = {
  // the values from the top parent to fill ach form fields.
  formdata: PropTypes.object.isRequired,
}
export default ACHFields;
