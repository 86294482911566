// AmountInputBox
//
//  desc:
//    amount box for payment page
//
//  props:
//    values:               (optional) if provided, and array of streets with objects for value and placeholder for AddressInput component. 
//    onChange:             (optional) if provided, will handle change events to update controlled data
//    onValidate:           (optional) event handler to handle validation of input
//    isValid:              (optional) if true changes UI to error state
//    amount:               (optional) value for input box
//    name:                 (optional) name of form input box
//    id:                   (required) id of form input box used to update the controlled data model.
//    maxLength:            (optional) max length of input box
//    tooltipText:          (optional) tool tip of textbox
//    autoFocus:            (required) to focus on control when edit link on review page navigates back to page with control
//    isReadOnly:           (optional) indicates if the amount input field should not be editable by the user


import React, { Component } from 'react';
import { Validation, Change } from '../Utility';
import { withFormsy } from 'formsy-react';
import './AmountInputBox.css';


// changes it to a number with 0 prefix if needed before decimal.
export const validateAmount = function(value) {
  // converting the value into number will also format any number starting with .
  // for example: .75 will be 0.75
  var validInput = Number(value);
  if (validInput <= 99999999.99 && validInput > 0 && /^[0-9]+(\.[0-9]{1,2})?$/.test(validInput)) {
    return true;
  }
  return false;
}

class AmountInputBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    // shouldn't need to do this because the onchange handler is already keeping
    // the state in the HOC in sync for the amount. This component is the only one
    // that updates the HOC state on input change to keep the old functionality.
    if (this.props.onChange) {
      this.props.onChange(this.props.getValue());
    }
  }

  formatMoney(value) {
    var number = Number(value);
    var money =  number.toFixed(2);
    // pad input text value with 0s to the hundreth place.
    // pad input text value with a 0 before a decimal point if needed.
    // as long as it doesn't cause it to exceed max number.
    return money;
  }

  handleBlur(evt) {
    if (evt.currentTarget.value)
      this.props.setValue(this.formatMoney(evt.currentTarget.value));
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });   
    if (this.props.isValid()) {
      evt.currentTarget.setCustomValidity('');
    } else {
      evt.currentTarget.setCustomValidity(errorMessage);
    }
  }

  handleChange(evt) {
    const amount = evt.currentTarget.value;
    // if (/^[0-9]{0,10}\.{0,1}[0-9]{0,2}$/.test(amount))
    if (/^([0-9]{0,10}|[0-9]{0,10}\.[0-9]{0,2})$/.test(amount))
    {
      this.props.setValue(amount);
      // this is the only form component that updates the HOC state onchange.
      // feels weird to update state on unmounting component but its just a work around
      // for wrapping the components in a "form" component. Ideally the form would be submitted
      // with the data maintained in its own form state.
      if (this.props.onChange) {
        this.props.onChange(amount);
      }
    }

  }

  // changes it to a number with 0 prefix if needed before decimal.
  validateAmount(value) {
    // converting the value into number will also format any number starting with .
    // for example: .75 will be 0.75
    var validInput = Number(value);
    if (validInput <= 99999999.99 && /^[0-9]+(\.[0-9]{1,2})?$/.test(validInput)) {
      return true;
    }
    return false;
  }

  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  render() {
    // const error = this.props.isValid ? '' : 'AmountInput_AmountError';
    const classes = `AmountInputBox with-prepended-icon`;
    let amount = '';
    if (this.props.getValue() == 0 || this.props.getValue())
    {
      amount = this.props.getValue();
    }
    const placeholder = this.props.placeholderText;
    const name = this.props.name;
    const id = this.props.id;
    const maxlength = this.props.maxLength;
    const tooltip = this.props.tooltipText;
    const style = {};
    const readOnly = this.props.isReadOnly

    if (this.props.width) {
      style.width = this.props.width;
    }
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div>
        <div className={ classes }>
          <label htmlFor={this.props.id}>
          {this.props.label}
          { requiredMark }
          {this.showErrorMessage()}
          </label>
          <i className='input-left-icon'>$</i>
          <input
              autoFocus={ this.props.autoFocus }
              style={style}
              id={ id }
              name={ name }
              value={ amount }
              placeholder={ placeholder }
              maxLength={ maxlength }
              title = { tooltip }
              type="text"
              onChange={ this.handleChange }
              onBlur={ this.handleBlur }
              readOnly={ readOnly }
          />
        </div>
      </div>
    );
  }
}

export default withFormsy(AmountInputBox);
