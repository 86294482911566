// Billing
//
//  desc:
//    Billing step and everything on that page. Right after payment step.
//
//  props:
//    formdata:             (required) the form data
//      name:               (optional) billing name for the ACH or CC fields
//      paymentMethod:      (optional) ACH or CC
//    onDataChange:         (optional) callback to update model
//    onValidate:           (optional) callback to validate model
//    storedCustomer:       contains any storeCustomer info for a logged in user 
//

import React, { Component } from 'react';
import PaymentSummary from '../../../PaymentSummary';
import SectionText from '../../../SectionText';
import PasswordNew from '../../../PasswordNew';
import Icon from '../../../Icon';
import AmountInputBox from '../../../AmountInputBox';
import FormInput from '../../../FormInput';
import CustomButton from '../../../CustomButton';
import DatePicker from '../../../DatePicker';
import RadioButton from '../../../RadioButton';
import {Validation, Change, Rule} from '../../../Utility';
import CreditCardFields from '../../CreditCardFields';
import ACHFields from '../../ACHFields';
import ContactFields from '../../ContactFields';
import DiscretionaryData from '../../DiscretionaryData';
import Notification from '../../../Notification';
import SpecialInstructionInput from '../../../SpecialInstructionInput';
import PaymentMethodInput, {
  PaymentMethod
} from '../../../PaymentMethodInput'
import './billing.css';


const CC = 'CC';
const ACH = 'ACH';

class Billing extends Component {
  constructor(props){
    super(props);
    this.state = {
      validNameOnCardVisited: false,
    }

    this.onClearMessage = this.onClearMessage.bind(this);
    this.onSaveStoredCustomerProfile = this.onSaveStoredCustomerProfile.bind(this);
    this.onCancelEditingStoredCustomerProfile = this.onCancelEditingStoredCustomerProfile.bind(this);
    this.onEditStoredCustomerProfileCC = this.onEditStoredCustomerProfileCC.bind(this);
    this.onEditStoredCustomerProfileACH = this.onEditStoredCustomerProfileACH.bind(this);
    this.onBillingNameChange = this.onBillingNameChange.bind(this);
    this.onBillingCreditCardNumberChange = this.onBillingCreditCardNumberChange.bind(this);
    this.onBillingPaymentMethodChange = this.onBillingPaymentMethodChange.bind(this);
    this.onBillingCreditCardExpChange = this.onBillingCreditCardExpChange.bind(this);
    this.onBillingCreditCardSecurityCodeChange = this.onBillingCreditCardSecurityCodeChange.bind(this);
    this.onBillingCountryChange = this.onBillingCountryChange.bind(this);
    this.onBillingAddressChange = this.onBillingAddressChange.bind(this);
    this.onBillingZipcodeChange = this.onBillingZipcodeChange.bind(this);
    this.onBillingCityChange = this.onBillingCityChange.bind(this);
    this.onBillingStateChange = this.onBillingStateChange.bind(this);
    this.onBillingPhoneChange = this.onBillingPhoneChange.bind(this);
    this.onBillingEmailChange = this.onBillingEmailChange.bind(this);
    this.onBillingCheckingAccountChange = this.onBillingCheckingAccountChange.bind(this);
    this.onBillingRoutingNumberChange = this.onBillingRoutingNumberChange.bind(this);
    this.onAccountTypeChange = this.onAccountTypeChange.bind(this);
    this.onSECCodeChange =  this.onSECCodeChange.bind(this);
    this.onBillingSpecialInstructionChange = this.onBillingSpecialInstructionChange.bind(this);
    this.onEditCreditCardOrACH = this.onEditCreditCardOrACH.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.validateNameOnCard = this.validateNameOnCard.bind(this);
  }

  componentWillUnmount() {
    if (this.props.setMessages) {
      this.props.setMessages('');
    }
  }

  onEditStoredCustomerProfileCC() {

    if (this.props.onUpdatingStoredCustomerProfile) {
      this.props.onUpdatingStoredCustomerProfile()
    }
    // 
    // if (this.props.disableNavigation) {
    //   this.props.disableNavigation();
    // }
    // 
    // if (this.props.onBillingPaymentMethodChange) {
    //   this.props.onBillingPaymentMethodChange(PaymentMethod.CC);
    // }
  }

  onEditStoredCustomerProfileACH() {

    if (this.props.onUpdatingStoredCustomerProfile) {
      console.log("updating stored profile ach cliecked555")
      
      this.props.onUpdatingStoredCustomerProfile()
    }

    // if (this.props.disableNavigation) {
    //   this.props.disableNavigation();
    // }
    // 
    // if (this.props.onBillingPaymentMethodChange) {
    //   this.props.onBillingPaymentMethodChange(PaymentMethod.ACH);
    // }
  }

  onSaveStoredCustomerProfile() {
    // this.setState({
    //   isUpdatingStoredCustomerProfile: false,
    // });
    if (this.props.onCancelEditingCCOrACH) {
      this.props.onCancelEditingCCOrACH();
    }
    if (this.props.enableNavigation) {
      this.props.enableNavigation();
    }
    if (this.props.onSaveStoredCustomerProfile) {
      this.props.onSaveStoredCustomerProfile();
    }
  }

  onCancelEditingStoredCustomerProfile() {
    // this.setState({
    //   isUpdatingStoredCustomerProfile: false,
    // });
    if (this.props.onCancelEditingCCOrACH) {
      this.props.onCancelEditingCCOrACH();
    }
    if (this.props.enableNavigation) {
      this.props.enableNavigation();
    }
  }

  onBillingPaymentMethodChange(value) {
    if (this.props.onBillingPaymentMethodChange) {
      this.props.onBillingPaymentMethodChange(value);
    }
  }
  
  onBillingCheckingAccountChange(value) {
    if (this.props.onBillingCheckingAccountChange) {
      this.props.onBillingCheckingAccountChange(value);
    }
  }
  
  onBillingRoutingNumberChange(value) {
    if (this.props.onBillingRoutingNumberChange) {
      this.props.onBillingRoutingNumberChange(value);
    }
  }

  onAccountTypeChange(value){
    if (this.props.onAccountTypeChange){
      this.props.onAccountTypeChange(value)
    }
  }

  onSECCodeChange(value){
    if (this.props.onSECCodeChange){
      this.props.onSECCodeChange(value)
    }
  }

  onBillingCountryChange(value) {
    if (this.props.onBillingCountryChange) {
      this.props.onBillingCountryChange(value);
    }
  }

  onBillingAddressChange(value) {
    if (this.props.onBillingAddressChange) {
      this.props.onBillingAddressChange(value);
    }
  }
  
  onBillingZipcodeChange(value) {
    if (this.props.onBillingZipcodeChange) {
      this.props.onBillingZipcodeChange(value);
    }
  }
  
  onBillingCityChange(value) {
    if (this.props.onBillingCityChange) {
      this.props.onBillingCityChange(value);
    }
  }
  
  onBillingStateChange(value) {
    if (this.props.onBillingStateChange) {
      this.props.onBillingStateChange(value);
    }
  }
  
  onBillingPhoneChange(value) {
    if (this.props.onBillingPhoneChange) {
      this.props.onBillingPhoneChange(value);
    }
  }
  
  onBillingEmailChange(value) {
    if (this.props.onBillingEmailChange) {
      this.props.onBillingEmailChange(value);
    }
  }
  
  onBillingNameChange(name) {
    if (this.props.onBillingNameChange) {
      this.props.onBillingNameChange(name);
    }
  }
  
  onBillingCreditCardNumberChange(value) {
    if (this.props.onBillingCreditCardNumberChange) {
      this.props.onBillingCreditCardNumberChange(value);
    }
  }
  
  onBillingCreditCardExpChange(value) {
    if (this.props.onBillingCreditCardExpChange) {
      this.props.onBillingCreditCardExpChange(value);
    }
  }
  
  onBillingCreditCardSecurityCodeChange(value) {
    if (this.props.onBillingCreditCardSecurityCodeChange) {
      this.props.onBillingCreditCardSecurityCodeChange(value);
    }
  }

  onBillingSpecialInstructionChange(value){
    if (this.props.onBillingSpecialInstructionChange) {
      this.props.onBillingSpecialInstructionChange(value);
    }
  }
  
  onClearMessage() {
    if (this.props.onClearMessage) {
      this.props.onClearMessage();
    }
  }
  
  onEditCreditCardOrACH() {
    if (this.props.onEditingCCOrACH) {
      this.props.onEditingCCOrACH();
    }
  }
  
  // just to update UI on blur event.
  validateNameOnCard(validation) {
    this.setState({
      validNameOnCardVisited: true,
    })
  }
    
  onValidate(validation) {
    // if (this.props.onValidate){
    //   this.props.onValidate(validation);
    // }
  }

  hasAutoFocus(fieldId) {
    if (this.props.location === undefined) return false;
    if (this.props.location.state === undefined) return false;

    return this.props.location.state.autoFocus == fieldId;
  }
  
  componentWillUnmount() {
  }

  componentDidMount() {
    if (this.props.resetPassword) {
      this.props.resetPassword(true, null);
    }
    // console.log(this.props.history.length)
    // clear autofocus state
    if (this.props.history !== undefined) {
      this.props.history.replace({
        pathname: '/Billing',
        state: {}
      });
    }
  }

  contactFields() {
    return (
      <ContactFields
        {...this.props}
      />
    )
  }
  
  isNotAuthOnlyTransaction() {
    return this.props.formdata.tranxType.toUpperCase() != 'AUTH' && this.props.formdata.tranxType.toUpperCase() != 'A';
  }
  
  hasACH() {
    // assume undefined is they have both cc and ACH but should usually be set.
    return this.props.formdata.hasACH === undefined || this.props.formdata.hasACH;
  }

  renderUpdateStoredCustomerButtons() {
    // const disable = !this.props.isStoredCustomerProfileValid;
    // if (this.state.isUpdatingStoredCustomerProfile) {
    //   return (
    //     <div>
    //       <button className="btn btn-primary" disabled={disable} onClick={this.onSaveStoredCustomerProfile} style={{marginRight: "5px"}}>Save</button>
    //       <button className="btn btn-primary" onClick={this.onCancelEditingStoredCustomerProfile}>Cancel</button>
    //     </div>
    //   )
    // } else {
    //   return null;
    // }

  }

  renderCreditCardFields() {
    let isCC = this.props.formdata.paymentMethod == 'CC';
    if (isCC) {
      return (
        <CreditCardFields
          isCCValid={ this.props.validateCC }
          isExpDateValid={ this.props.validateExp }
          isSecurityCodeValid={ this.props.validateSecurityCode }
          autoFocus={ this.hasAutoFocus('billing_account') }
          onBillingCreditCardExpChange={ this.onBillingCreditCardExpChange }
          onBillingCreditCardSecurityCodeChange={ this.onBillingCreditCardSecurityCodeChange }
          onBillingCreditCardNumberChange={ this.onBillingCreditCardNumberChange }
          formdata={ this.props.formdata } />
      )
    } else {
      return (
        <ACHFields
          isCheckingAccountValid={ this.props.validateCheckingAccount }
          isCheckingRoutingNumber={ this.props.validateRoutingNumber }
          autoFocus={ this.hasAutoFocus('billing_account') }
          onBillingCheckingAccountChange={ this.onBillingCheckingAccountChange }
          onBillingRoutingNumberChange={ this.onBillingRoutingNumberChange }
          onAccountTypeChange = { this.onAccountTypeChange }
          onSECCodeChange = { this.onSECCodeChange }
          formdata={ this.props.formdata } />
      )
    }
  }

  paymentFields() {
    const name = this.props.formdata.name;
    // isCC and hasACH is different fyi
    let isCC = this.props.formdata.paymentMethod == 'CC';
    
    // ensure always true if merchant does not have ACH.
    if (!this.hasACH()) {
      isCC = true; 
    }
    // ensure always true if merchant ACH processor is VCI but SEC code is invalid.
    if (this.props.formdata.hasTraceACH && !this.props.validSecCode){
      isCC = true
    }

    // let updateStyle = {};
    // if (this.state.isUpdatingStoredCustomerProfile) {
    //   updateStyle = { zIndex: "2", left: "-30px", border: "1px solid #8a4419", boxShadow: "10px 10px 5px grey", backgroundColor: "gainsboro", padding: "30px", position: "absolute" };
    // }
    //check to see if a user is logged in and has stored data for the chosen payment type
    if (this.hasStoredInfo(isCC))
    { //a logged in user has stored info for the desired payment, so we should display that instead of the regular payment fields 
      if (isCC)
      { //display stored cc data
        return this.renderStoredCCCustomeInfo()
      }
      else
      { //display stored ach data
        return this.renderStoredACHCustomeInfo()
      }
    }
    else 
    { //show payment fields for entering full credit card or ach data 
      //show payment fields also if logged in and updating customer stored profile.
      return (
        <div>

          {this.renderUpdateStoredCustomerButtons()}

            <FormInput
              maxLength='50'
              id="name"
              name='name'
              // validations='minLength:1'
              validationErrors={ {
                // 'minLength': 'This field is required',
                "isDefaultRequiredValue":"This field is required"
              } }
              required
              innerRef={c => {
                window.formFields['name'] = c
              }}
                  value={this.props.formdata.name || ''}
                  //isValid={ this.props.validNameOnCard || !this.state.validNameOnCardVisited }
                  placeholder = "Full Name"
                  onChange={ this.onBillingNameChange }
                  //onBlur={ this.validateNameOnCard }
                  label={ isCC ? 'Name on Card' : 'Full Name' }
            />
            {
              this.renderCreditCardFields()
            }
            {this.contactFields()}
        </div>
      )
    }
  }
  
  specialInstruction(){
    // <textarea value={ this.props.formdata.specialInstruction } col="50" row="5" maxLength="250" name="Description" id="Description" onChange={this.onBillingSpecialInstructionChange}/>
    
    return(
      <div className='control'>
        <SpecialInstructionInput 
          name="description" id="description"
          label='Special Instructions'
          value={this.props.formdata.specialInstruction}
          onChange={this.onBillingSpecialInstructionChange}
        />
      </div>
    )
  }
  

  displayDiscretionaryData()
  {
    const paymentMethodAch = this.props.formdata.paymentMethod === "ACH";
    const hasTraceACH = this.props.formdata.hasTraceACH;

    if (paymentMethodAch && hasTraceACH) {
      return null;
    }
    if (this.props.formdata.disc && Array.isArray(this.props.formdata.disc))
    {
      return(
        <div className='control'>
          <DiscretionaryData showError={ this.props.showError } discDataArray={this.props.formdata.disc} onDiscretionaryDataChange={this.props.onDiscretionaryDataChange}/>
        </div>
      )
    }

    return
  }
  
  isLoggedIn() {
    return this.props.storedCustomer.isLoggedIn();
  }

  //function checks to ensure that the customer, if logged in, has stored info regarding the payment type in question
  hasStoredInfo(isCC)
  {
    let hasInfo = false;
    if (this.props.storedCustomer.token)
    { //we have a logged in customer
      //check to see if they have data for the piece in question
      if (isCC)
      { //check for credit card info
        if (this.props.storedCustomer.customer.Last4CC && this.props.storedCustomer.customer.ExpMnth && this.props.storedCustomer.customer.ExpYr)
        { //they have the proper credit card info stored in the customer profile
          hasInfo = true;
        }
      }
      else  //currently, the else is if we are checking for ACH stored info 
      {
        if (this.props.storedCustomer.customer.DDA && this.props.storedCustomer.customer.TR)
        { //they have the proper ACH info stored in the customer profile
          if (this.props.formdata.hasTraceACH) {
    
            hasInfo = !!this.props.storedCustomer.customer.AccountType
          } else {
            hasInfo = true;
          }
        }      
      }
    }

    return hasInfo;
  }

  
  renderStoredCCCustomeInfo()
  {
    if (this.props.storedCustomer.customer.Last4CC && this.props.storedCustomer.customer.ExpMnth && this.props.storedCustomer.customer.ExpYr)
    { //currently will only render something if there is info for stored credit cards 
      let last4 = this.props.storedCustomer.customer.Last4CC                    
      let expDate = this.props.storedCustomer.customer.ExpMnth + "/" + this.props.storedCustomer.customer.ExpYr       
      return <div className='storedInfo row'>
        <div className='col-10'><b>Stored Card ending in:</b> {last4} <b>exp:</b> {expDate}</div>
        <div className='col-2'>{this.renderEditCCStoredProfileButtons()}</div>
      </div>
    }
  }


  renderStoredACHCustomeInfo()
  {
    // Processor with TraceACH merchant customer will have AccountType required
    if(this.props.formdata.hasTraceACH && !this.props.storedCustomer.customer.AccountType){
      return null
    }

    if (this.props.storedCustomer.customer.DDA && this.props.storedCustomer.customer.TR)
    { //currently will only render something if there is info for stored credit cards 
      let checkingAccount = this.props.storedCustomer.customer.DDA                    
      let routingNumber = this.props.storedCustomer.customer.TR 
      let accountType = this.props.storedCustomer.customer?.AccountType;
      return <div className='storedInfo'>
        <div className='row'>
          <div className='col-10'><b>Stored Account Number:</b> {checkingAccount}</div>
          <div className='col-2'>&nbsp;</div>
        </div>
  
        <div className='row'>
          <div className='col-10'><b>Stored Routing Number:</b> {routingNumber}</div>
          <div className='col-2'>{!this.props.formdata.hasTraceACH && this.renderEditACHStoredProfileButtons()}</div>
        </div>
        {this.props.formdata.hasTraceACH &&
        <>
          <div className='row'>
            <div className='col-10'><b>Account Type:</b> {accountType}</div>
            <div className='col-2'>{this.renderEditACHStoredProfileButtons()}</div>
          </div>
        </>
        }
       
      </div>
    }
  }
  
  renderEditCCStoredProfileButtons() {
    if (this.isLoggedIn() && !this.state.isUpdatingStoredCustomerProfile) {
      return (
        <div className="btn btn-link" style={{marginRight: "5px"}} onClick={this.onEditStoredCustomerProfileCC}>Edit</div>
      )
    } else {
      return null;
    }
  }

  renderEditACHStoredProfileButtons() {
    if (this.isLoggedIn() && !this.state.isUpdatingStoredCustomerProfile) {
      return (
        <button className="btn btn-link" onClick={this.onEditStoredCustomerProfileACH}>Edit Info</button>
      )
    } else {
      return null;
    }
  }
  
  renderMessages() {
    const animate = this.props.notifications != null && this.props.notifications != "";

    return (
      <div className='row'>
        <Notification 
            animateMessage={animate}
            onAnimationEnd={this.onClearMessage}>
          {this.props.notifications}
        </Notification>
      </div>
    )
  }

  render() {
    const paymentMethodCCText = "Pay with CC";
    const paymentMethodACHText = "Pay with Bank Account";
    
    return (
      <div className='billing-fields'>

        <label> * indicates a required field </label>
        { this.renderMessages() }

        <div className='row'>
        <PaymentMethodInput
          id={ PaymentMethod.CC }
          name='paymentMethod'
          value={ this.props.formdata.paymentMethod }
          id="paymentMethod"
          tranxType={this.props.formdata.tranxType}
          hasACH={this.props.formdata.hasACH}
          hasTraceACH={this.props.formdata.hasTraceACH}
          validSecCode={this.props.validSecCode}
          labelText={paymentMethodCCText}
          selected={ this.props.formdata.paymentMethod == PaymentMethod.CC } 
          onChange={this.onBillingPaymentMethodChange}/>



        </div>

        <div style={{clear: 'both'}}>
          <div>
          {this.paymentFields()}
          </div>
        </div>

        {this.specialInstruction()}
        {this.displayDiscretionaryData()}
      </div>
    );
  }
}

export default Billing;
