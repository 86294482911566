import React, { Component } from 'react';
// import Formsy from 'formsy-react';
import Form from './';
import {
  CustomerACHFields as CustomerACHFieldsModel,
  CustomerBillingContactFields as CustomerBillingContactFieldsModel,
  CustomerCreditCardFields as CustomerCreditCardFieldsModel,
} from './Model';
import ContactFields from '../PaymentForm/ContactFields';
import CreditCardFields from '../PaymentForm/CreditCardFields';
import ACHFields from '../PaymentForm/ACHFields';
import FormInput from '../FormInput';
import PropTypes from 'prop-types';
import { 
  UpdateCustomerAccount
} from '../../../../lib/comms';
import {BackDrop} from '../Modal';
 
// this update customer component only shows when 
// customer is logged in and token exists
class UpdateStoredProfileForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      // editing CC and ACH explicitly and intentional because it is masked
      isEditingCC: false,
      isEditingACH: false,
      // enabled only when customer update form is valid 
      enableSaveProfileButton: false,
    };
    this.submit = this.submit.bind(this);
    this.editingACH = this.editingACH.bind(this);
    this.editingCC = this.editingCC.bind(this);
    this.closeThis = this.closeThis.bind(this);
  }

  submit(formdata) {
    // Method updates only
    if (!this.props.context.token) {
      throw "Unexpected Error. No Token"
    }

    const customerRequestToken = this.props.context.token;
    const data = {
      merchant_id: sessionStorage.getItem('m'), //required
      // EMAIL: this.props.formdata.email, //required
      CC: formdata.cc, //required
      // Password: this.state.accountCreationPassword, //there is no check on VT
      DDA: formdata.checkingAccount, //there is no check on VT
      TR: formdata.routingNumber, //there is no check on VT
    
      BNAME: formdata.name, //name on card
      BADDRESS: formdata.address[0],
      BADDRESS2: formdata.address[1],
      BCITY: formdata.city,
      BSTATE: formdata.state,
      BZIP: formdata.zip,
      // VT legacy system when loading defaults overrides bcountry so not setting it for now
      //system uses 2 char country code uppercase
      BCOUNTRY: formdata.country.toUpperCase(),
      PHONE: formdata.phone,
      Exp: formdata.exp, //gets sent as month and year to VT
      token: customerRequestToken,
      AccountType: formdata.AccountType,
      SECCode: formdata.SECCode,
    }

    // ex. response:
    // {
    //   "merchant_id":"49456","status":400,
    //   "message":"Account Update Failed. Please provide valid Transit Routing Number<BR \u002f>"
    // }
    UpdateCustomerAccount(data).then((resp) => {
      if (resp !== undefined && resp.data !== undefined && (resp.data.status == 200 || resp.data.status == "200")) {
        try {
          this.props.context.updatePaymentInfo(formdata);
          this.props.close("Profile Updated");
          // Update parent form data
          if (this.props.onSubmit !== undefined) {
            this.props.onSubmit(formdata);
          }
        } catch(err) {
          console.warn(err);
          this.props.close("Unexpected Error. Please contact administrator.");
        }
      } else {
        // console.log("resp", resp)
        // these would be server errors like invalid values in request.
        this.setState({
          response: resp.data.message,
        });
      }
    }).catch((err) => {
    
      // these messages would usually be network errors so at least close the dialog box.
      if (err.response && err.response.status === 404) {
        this.props.close('Network Error. Failed');
      } else if (err.response && err.response.message) {
        this.props.close('Unexpected Error.' & err.response.message);
      } else {
        this.props.close('Unexpected Error.');
      }
  
    });
  }
  
  //function checks to ensure that the customer, if logged in, has stored info regarding the payment type in question
  hasStoredInfo(isCC) {
    if  (this.props.context.token) {
      return true;
    } else {
      return false;
    }
  }
  
  renderStoredCCCustomeInfo()
  {
    if (this.props.context.customer && this.props.context.customer.Last4CC && this.props.context.customer.ExpMnth && this.props.context.customer.ExpYr)
    { //currently will only render something if there is info for stored credit cards 
      let last4 = this.props.context.customer.Last4CC                    
      let expDate = this.props.context.customer.ExpMnth + "/" + this.props.context.customer.ExpYr       
      return <div className='storedInfo row'>
        <div className='col-12'><b>Stored Card ending in:</b> {last4} <b>exp:</b> {expDate}</div>
      </div>
    }
  }

  renderStoredACHCustomeInfo()
  {
    // Processor with TraceACH merchant customer will have AccountType required
    if(this.props.parentFormData.hasTraceACH && !this.props.context.customer.AccountType){
      return null
    }
      
    if (this.props.context.customer && this.props.context.customer.DDA && this.props.context.customer.TR)
    { //currently will only render something if there is info for stored credit cards 
      let checkingAccount = this.props.context.customer.DDA;
      let routingNumber = this.props.context.customer.TR;
      let accountType = this.props.context.customer?.AccountType;
      return <div className='storedInfo'>
        <div className='row'>
          <div className='col-12'><b>Stored Account Number:</b> {checkingAccount}</div>
        </div>
  
        <div className='row'>
          <div className='col-12'><b>Stored Routing Number:</b> {routingNumber}</div>
        </div>

        {this.props.parentFormData.hasTraceACH && 
        <>
          <div className='row'>
            <div className='col-12'><b>Account Type:</b> {accountType}</div>
          </div>
        </>
        }
      </div>
    }
  }
  
  hasACH() {
    return this.props.hasACH;
  }
  
  editingACH(e) {
    const isChecked = e.target.checked;
    this.setState({ isEditingACH: isChecked });
  }
  
  editingCC(e) {
    const isChecked = e.target.checked;
    this.setState({ isEditingCC: isChecked });
  }
  
  creditCardFields() {
    const editing = this.state.isEditingCC;
    return (
      <div style={{border: '1px solid gainsboro', padding: '5px', marginBottom: '5px'}}>

        <div className='row'>
          <div className='col-md-8'>
            <input
              id='editingCC'
              onChange={ this.editingCC }
              type='checkbox' checked={ editing }
            />
            <label style={{marginLeft: '10px'}}>
              Update Credit Card Account
            </label>
          </div>
          <div className='col-md-4'></div>
        </div>

        {
          editing ? <div className='row'>
            <div className='col-md-12'>
              <CreditCardFields formdata={ CustomerCreditCardFieldsModel(this.props.context), this.props.parentFormData } />
            </div>
          </div> : this.renderStoredCCCustomeInfo()
        }

      </div>
    )
    // <CreditCardFields formdata={ CustomerCreditCardFieldsModel(this.props.context) } />
    // <a style={{width: '150px'}} className='btn btn-primary' href='' onClick={ this.editingCC }>Edit Credit Card</a>
  }
  
  achFields() {
    const editing = this.state.isEditingACH;
    return (
      <div style={{border: '1px solid gainsboro', padding: '5px', marginTop: '5px'}}>

        <div className='row'>
          <div className='col-md-8'>
            <input 
              onChange={ this.editingACH }
              type='checkbox' checked={ editing }
            />
            <label style={{marginLeft: '10px'}}>
              Update Account Number
            </label>
          </div>
          <div className='col-md-4'></div>
        </div>

        {
          editing ? <div className='row'>
            <div className='col-md-12'>
              <ACHFields formdata={ CustomerACHFieldsModel(this.props.context), this.props.parentFormData } />
            </div>
          </div> : this.renderStoredACHCustomeInfo()
        }

      </div>
    )
  }
  
  closeThis(e) {
    this.props.close("");
    e.preventDefault();
  }

  render() {
    let hasACH = this.props.hasACH;
    let isCC = this.props.paymentMethod == 'CC' || !hasACH;
    let billingName = '';
    if (this.props.context.customer) {
      billingName = this.props.context.customer.BNAME;
    }
    return (
      <div>
      
        <BackDrop onClick={ this.closeThis } 
                  enabled={ true }/>

          <div style={{zIndex: '10'}} className='fixed-modal modal-position box-card'>

            <Form style={{
                position: 'fixed',
                backgroundColor: 'gainsboro',
                border: '2px solid gray',
                marginTop: '50px',
                padding: '30px',
                height: '500px',
                borderRadius: '5px',
                overflow: 'scroll',
              }}
              onValidSubmit={ this.submit }
              onValid={ () => this.setState({enableSaveProfileButton: true}) }
              onInvalid={ () => this.setState({enableSaveProfileButton: false}) } 
            >

              <div style={{textAlign: 'right', fontSize: '20px'}}>
                <label style={{cursor: 'pointer'}} onClick={() => this.props.close()}>
                &#10006;
                </label>
              </div>

              <div>
                <FormInput
                  maxLength='50'
                  id="name"
                  name='name'
                  // validations='minLength:1'
                  validationErrors={ {
                    // 'minLength': 'This field is required',
                    "isDefaultRequiredValue":"This field is required"
                  } }
                  required
                  value={billingName || ''}
                  //isValid={ this.props.validNameOnCard || !this.state.validNameOnCardVisited }
                  placeholder = "Billing Name"
                  //onBlur={ this.validateNameOnCard }
                  label={ 'Billing Name' }
                />

                <br/>

                {
                  this.creditCardFields()
                }

                {
                  this.hasACH() && this.achFields()
                }

                <ContactFields formdata={ CustomerBillingContactFieldsModel(this.props.context) } />
              </div>

              <div style={{marginTop:'20px', marginBottom:'50px'}}>
                <button style={{marginRight: '10px'}} className='btn' type="button" onClick={() => this.props.close()}>Cancel</button>
                <button className='btn btn-primary' type='submit' disabled={!this.state.enableSaveProfileButton}>Submit</button>
                <span style={{color:'red', margin: '20px'}}>
                  <div dangerouslySetInnerHTML={{__html: this.state.response}} />
                </span>
              </div>

            </Form>

          </div>
      </div>
    );
  }
}

UpdateStoredProfileForm.propTypes = {
  // the stored profile retrieved from the api request
  context: PropTypes.object.isRequired,
  // calls parent to unload this component
  close: PropTypes.func.isRequired,
}

export default UpdateStoredProfileForm;
